import React, {useEffect, useState} from 'react';
import {useSearchParams} from 'react-router-dom';

import FeedBottomButton from '../../components/Button/FeedBottomButton/FeedBottomButton';
import Comment from '../../components/Comment/Comment';
import PinDetailDrawer from '../../components/Drawer/PinDetailDrawer/PinDetailDrawer';
import Icon from '../../components/Icon/Icon';
import FeedDeatilLayout from '../../components/Layout/FeedDeatilLayout';
import NaverMap from '../../components/Map/NaverMap';
import ProfileArea from '../../components/Profile/ProfileArea';
import axioswrapper from '../../utils/Axios';
import {localStorageUtil} from '../../utils/LocalStorage';
import styles from './FeedDetail.module.scss';

function FeedDetail(props) {
    const [serchParams] = useSearchParams();

    // 리스트에서 댓글달기를 클릭 후 상세로 넘어왔는지 체크하기 위해 넣어둠
    const writeCommentOpen = serchParams.get('flag');
    const feedId = serchParams.get('feedId');

    const [visibleCommentInput, setVisibleCommentInput] = useState(false);
    const [pinDetailDrawerOpen, setPinDetailDrawerOpen] = useState(false);
    const [pinDetailDrawerIsOpen, setPinDetailDrawerIsOpen] = useState(false);
    const [pinDetailData, setPinDetailData] = useState({
        pinList: []
    });
    const [hashListData, setHashListData] = useState([]);
    const [replyList, setReplyList] = useState([]);
    const [likeOn, setLikeOn] = useState(false);
    const [likeCount, setLikeCount] = useState(0);
    const [selectedPinData, setSelectedPinData] = useState();
    const [isScrollBottom, setIsScrollBottom] = useState(false);
    const [mapZoomLevel, setMapZoomLevel] = useState(13);

    // 좋아요 클릭
    const onClickLike = () => {
        const likeUrl = !likeOn ? 'like/create' : 'like/delete';

        const payload = {
            feedId: pinDetailData.id,
            memberId: localStorageUtil.get('memberId')
        };

        axioswrapper
            .Axios('POST', likeUrl, payload)
            .then((response) => {
                console.log(`${likeUrl} ok`, response);
                setLikeOn(!likeOn);
                setLikeCount(response.data.data.count);
            })
            .catch((error) => {
                console.log(`${likeUrl} error`, error);
            });
    };

    // 댓글달기 클릭
    const onClickWriteComment = () => {
        setVisibleCommentInput(true);
    };

    // 핀 상세 Drawer 열기
    const openPinDetailDrawer = () => {
        setPinDetailDrawerIsOpen(true);
    };

    // 핀 상세 Drawer 닫기
    const closePinDetailDrawer = () => {
        setPinDetailDrawerIsOpen(false);
    };

    // 핀 상세 Drawer 가 열려있을때 > 컨텐츠 영역 클릭시 핀 상세 Drawer 닫힘
    const handleOutsideClick = (event) => {
        const drawer = document.querySelector('.MuiDrawer-root');

        if (drawer && !drawer.contains(event.target)) {
            closePinDetailDrawer();
        }
    };

    const clickPinHandler = (data) => {
        setSelectedPinData(data);
    };

    const onClickFollowBtn = () => {
        const followUrl = !pinDetailData.isFollow ? 'follow/create' : 'follow/delete';
        const payload = {
            followerId: pinDetailData.memberId,
            followingId: localStorageUtil.get('memberId')
        };
        axioswrapper
            .Axios('POST', followUrl, payload)
            .then((response) => {
                console.log(`${followUrl} ok`, response);
                axioswrapper
                    .Axios('GET', `feed/detail?feedId=${feedId}&memberId=${localStorageUtil.get('memberId')}`)
                    .then((response) => {
                        setPinDetailData(response.data.data);
                        setReplyList(response.data.data.replyDto.replyList);
                        setLikeCount(response.data.data.likeCnt);
                        setLikeOn(response.data.data.isLike);
                    })
                    .catch((error) => {
                        console.log('feed/detail error', error);
                    });
            })
            .catch((error) => {
                console.log(`${followUrl} error`, error);
            });
    };

    const feedDetailConOnScroll = (event) => {
        const {scrollHeight, scrollTop, clientHeight} = event.target;
        const tolerance = 1; // 허용 오차 범위 (px 단위)

        // 이 조건은 사용자가 스크롤을 최하단 근처로 이동했을 때 true가 됩니다.
        if (scrollHeight - scrollTop - clientHeight <= tolerance) {
            setIsScrollBottom(true);
        } else {
            setIsScrollBottom(false);
        }
    };

    useEffect(() => {
        if (writeCommentOpen === 'writeCommentOpen') {
            setVisibleCommentInput(true);
        } else {
            setVisibleCommentInput(false);
        }

        if (feedId) {
            axioswrapper
                .Axios('GET', `feed/detail?feedId=${feedId}&memberId=${localStorageUtil.get('memberId')}`)
                .then((response) => {
                    console.log('feedData', response);
                    setHashListData(response.data.data?.tagList);
                    setPinDetailData(response.data.data);
                    setReplyList(response.data.data.replyDto.replyList);
                    setLikeCount(response.data.data.likeCnt);
                    setLikeOn(response.data.data.isLike);
                    setMapZoomLevel(response.data.data.zoomLevel);
                })
                .catch((error) => {
                    console.log('feed/detail error', error);
                });
        }
    }, []);

    return (
        <FeedDeatilLayout
            pageTitle={`${pinDetailData?.nickname !== undefined ? pinDetailData?.nickname : ''}의 게시물`}
            pinDetailData={pinDetailData}
            back={true}
            nav={false}
            isMine={pinDetailData.isMine}
            whareCalled="feedDetail"
        >
            <div className={styles.feedDetailWrap}>
                <div className={styles.feedDetailCon} onScroll={feedDetailConOnScroll}>
                    <div className={styles.mapAreaWrap}>
                        {/* 지도 노출 영역 */}
                        <div className={styles.mapArea}>
                            <NaverMap
                                pinList={pinDetailData.pinList}
                                openPinDetailDrawer={() => openPinDetailDrawer()}
                                receivePinData={clickPinHandler}
                                zoomLevel={mapZoomLevel}
                                mainPinIcon={pinDetailData.mainUrl}
                                subPinIcon={pinDetailData.subUrl}
                            />
                        </div>

                        {/* 좋아요 */}
                        <button type="button" className={styles.btnLike} onClick={onClickLike}>
                            <Icon name={likeOn ? 'like-on' : 'like'} width="20" height="20" fill="#FF4F4F" />
                            <span className={styles.num}>{likeCount}</span>
                        </button>
                    </div>
                    <div className={styles.postDetailArea} onClick={handleOutsideClick}>
                        {/* 프로필 영역 */}
                        <ProfileArea feedDTO={pinDetailData} onClickFollowBtn={onClickFollowBtn} />
                        <div className={styles.txtAreaWrap}>
                            <div className={styles.txtArea}>{pinDetailData.desc}</div>
                        </div>

                        {/* 해시태그 영역 */}
                        {hashListData?.length > 0 && (
                            <div className={styles.hashTagList}>
                                {hashListData.map((item, idx) => {
                                    return <span key={idx}>#{item}</span>;
                                })}
                            </div>
                        )}

                        {/* 댓글 영역 */}
                        <Comment
                            visibleCommentInput={visibleCommentInput}
                            replyList={replyList}
                            feedId={pinDetailData.id}
                            isMine={pinDetailData.isMine}
                            isScrollBottom={isScrollBottom}
                        />
                    </div>
                </div>

                {/* 피드 하단 버튼 영역 */}
                <FeedBottomButton
                    onClickLike={onClickLike}
                    likeOn={likeOn}
                    onClickWriteComment={onClickWriteComment}
                    feedId={feedId}
                />
            </div>

            {/* 핀 선택시 */}
            <PinDetailDrawer
                isOpen={pinDetailDrawerIsOpen}
                onClose={closePinDetailDrawer}
                pinData={selectedPinData}
            />
        </FeedDeatilLayout>
    );
}

export default FeedDetail;
