import React, {useEffect, useState, useRef} from 'react';
import {Drawer, Button} from '@mui/material';
import styles from './MergePinDrawer.module.scss';
import {useSelector, useDispatch} from 'react-redux';
import Icon from '../../Icon/Icon';

function MergePinDrawer(props) {
    const {margePinDrawerOpen, selectedItemCount, goToNextStep, mergePinStep} = props;

    const goNextStep = () => {
        let state = '';
        if(mergePinStep === 'merge') {
            state = 'delete';
        } else if(mergePinStep === 'delete') {
            state = 'post';
        }
        goToNextStep(state);
    }

    return (
        <Drawer anchor="bottom" open={margePinDrawerOpen} hideBackdrop={true}>
            <div className={styles.contentsWrap}>
                <div className={styles.noti}>
                    <span className={styles.notiText}>
                        {mergePinStep === 'merge' ? ('게시물과 즐겨찾기를 오가며 선택해 보세요'
                        ) : (
                            mergePinStep === 'delete' ? ('장소를 삭제하거나 대표로 설정해 보세요'
                        ) : (
                            <></>
                        ))}
                    </span>
                </div>

                <div className="fixedBottomBtnArea">
                    <Button
                        variant="contained"
                        fullWidth={true}
                        size="large"
                        onClick={goNextStep}
                        disabled={selectedItemCount < 1}>
                            {mergePinStep === 'merge' ? (`${selectedItemCount}개 핀 선택`
                            ) : (
                                mergePinStep === 'delete' ? ('다음'
                            ) : (
                                <></>
                            ))}
                        
                    </Button>
                </div>            
            </div>
        </Drawer>
    );
}

export default MergePinDrawer;
