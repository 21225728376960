import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {useLocation, useNavigate, useSearchParams} from 'react-router-dom';

import './App.global.scss';
import AppRoutes from './AppRoutes';
import {stackRouterReset} from './_helpers/StackRouter';
import {history} from './_helpers/history';
import {setIsLoginLoading, setIsLogined} from './_store/auth.slice';
import {
    resetBackpressedCount,
    setAppVersion,
    setBackpressedCount,
    setIsPushSetting
} from './_store/etc.slice';
import {setMainFeedList} from './_store/feed.slice';
import {setLocDrawerOpen} from './_store/makePin.slice';
import {setPhotoCameraPermission} from './_store/permission.slice';
import {setReplyList} from './_store/reply.slice';
import {setReportDrawerOpen} from './_store/report.slice';
import store from './_store/store';
import {openImgLoadWindowUploadImg} from './components/Input/UploadImg/UploadImg';
import {setCenter} from './components/Map/SwipeMap/SwipeMap';
import {openImgLoadWindowModifyProfile} from './pages/MyPage/ModifyProfile';
import {appUtils} from './utils/AppUtils';
import axioswrapper from './utils/Axios';
import {localStorageUtil} from './utils/LocalStorage';
import getUserAgent from './utils/UserAgent';

function App() {
    const [serchParams] = useSearchParams();
    // init custom history object to allow navigation from
    // anywhere in the react app (inside or outside components)
    history.navigate = useNavigate();
    history.location = useLocation();
    const dispatch = useDispatch();

    const userId = localStorageUtil.get('memberId');
    const userName = localStorageUtil.get('userName');
    const refreshToken = localStorageUtil.get('refreshToken');

    console.log('테스트!!!!!!!');

    useEffect(() => {
        window.addEventListener('message', (event) => {
            getMessage(event);
        });

        document.addEventListener('message', (event) => {
            getMessage(event);
        });

        // Listen for authorization success.
        document.addEventListener('AppleIDSignInOnSuccess', (event) => {
            // Handle successful response.
            console.log(event.detail.data);
        });

        // Listen for authorization failures.
        document.addEventListener('AppleIDSignInOnFailure', (event) => {
            // Handle error.
            console.log(event.detail.error);
        });

        // 앱 버전 확인
        window.ReactNativeWebView?.postMessage(JSON.stringify({type: 'appVersion'}));

        // 테스트코드
        window.ReactNativeWebView?.postMessage(JSON.stringify({type: 'webViewLog', value: serchParams}));
        window.ReactNativeWebView?.postMessage(JSON.stringify({type: 'webViewLog', value: window.location.href}));
    }, []);

    const getMessage = (event) => {
        const myState = store.getState();

        if (typeof event.data === 'object') {
            return;
        }

        let data;

        try {
            data = JSON.parse(event.data);
        } catch (error) {
            return;
        }

        console.log('data.type >>>>>', data.type);

        if (data.type === 'addLog') {
            appUtils.sendLogMessage('it is called RN', data.result);
        }

        if (data.type === 'isRealDeleteFeed') {
            const memberId = localStorageUtil.get('memberId');
            const feedData = myState.feed;

            // 0번: 취소, 1번: 게시물 삭제
            if (data.result === 1) {
                const payload = {
                    feedId: feedData.tobeDeleteFeedItem.id
                };

                axioswrapper
                    .Axios('POST', 'feed/delete', payload)
                    .then((response) => {
                        console.log('피드 삭제 성공!');
                        stackRouterReset('Home', '/');

                        // 피드 삭제 후 페이지 이동, 데이터 업데이트
                        if (history.location.pathname === '/') {
                            // 메인(홈) 피드 리스트일 때
                            axioswrapper
                                .Axios('GET', `feed/list?memberId=${memberId}`)
                                .then((response) => {
                                    // console.log(
                                    //     '게시물 삭제 후 메인 피드 목록 조회 성공',
                                    //     response.data.data
                                    // );
                                    dispatch(setMainFeedList(response.data.data.lists));
                                })
                                .catch((error) => {
                                    console.log('메인 피드 목록 조회 실패', error);
                                });
                        }
                    })
                    .catch((error) => {
                        console.log('피드 삭제 에러', error);
                    });
            }

            console.log('isRealDeleteFeed');
        } else if (data.type === 'isRealDeleteComment') {
            const replyData = myState.reply;
            // 0번: 댓글 삭제, 1번: 취소
            if (data.result === 0) {
                const payload = {
                    replyId: replyData.tobeDeleteReplyItem.replyId
                };

                axioswrapper
                    .Axios('POST', 'reply/delete', payload)
                    .then((response) => {
                        const newCommentList = replyData.replyList.filter(
                            (listItem) => listItem !== replyData.tobeDeleteReplyItem
                        );

                        dispatch(setReplyList(newCommentList));
                    })
                    .catch((error) => {
                        console.log('reply/delete error', error);
                    });
            }
        } else if (data.type === 'gpsPermissonCheck') {
            // 위도 경도 정보가 없으면 default 서울시청
            const locationInfo = {
                open: true,
                lat: data.result.lat,
                lng: data.result.lng
            };
            dispatch(setLocDrawerOpen(locationInfo));

            if (data.result.whareCalled === 'swpieMap') {
                setCenter(data.result.lat, data.result.lng);
            }
        } else if (data.type === 'kakaoLoginAndroid') {
            try {
                appUtils.sendLogMessage('App.getMessage().data.type === kakaoLoginAndroid', '', 'NORMAL');

                const payload = {
                    authorizationCode: data.result.accessToken,
                    userAgent: getUserAgent()
                };

                dispatch(setIsLoginLoading(true));
                axioswrapper
                    .Axios('POST', `auth/kakao-login`, payload)
                    .then((response) => {
                        // console.log('auth/kakao-login ok', response);
                        localStorageUtil.set('loginType', 'kakao');
                        localStorageUtil.set('kakaoToken', response.data.data.memberDto.kakaoToken);
                        localStorageUtil.set('email', response.data.data.memberDto.email);
                        localStorageUtil.set('memberId', response.data.data.memberDto.id);
                        localStorageUtil.set('userName', response.data.data.memberDto.username);
                        localStorageUtil.set('loginAuth', response.data.data.memberDto.auth);

                        dispatch(setIsLogined(response.data.data.isLogined));
                        dispatch(setIsLoginLoading(false));

                        if (response.data.data.memberDto.isLogined) {
                            const date = new Date();
                            localStorageUtil.set('accessToken', response.data.data.accessToken);
                            localStorageUtil.set('refreshToken', response.data.data.refreshToken);
                            date.setTime(date.getTime() + response.data.data.expiresIn * 1000);

                            localStorageUtil.set('expireAt', date);
                            stackRouterReset('Home', '/');
                        } else {
                            history.navigate(`/join`);
                        }
                    })
                    .catch((error) => {
                        console.log('error', error);

                        localStorageUtil.set('loginType', '');
                        localStorageUtil.set('kakaoToken', '');
                        localStorageUtil.set('email', '');
                        localStorageUtil.set('loginAuth', '');

                        dispatch(setIsLogined(false));
                        dispatch(setIsLoginLoading(false));
                    })
                    .finally(() => {
                        dispatch(setIsLoginLoading(false));
                    });
            } catch (error) {
                console.log('auth/kakao-login error', error);
                appUtils.sendLogMessage(
                    'App.getMessage().(data.type === kakaoLoginAndroid)',
                    error.message,
                    'ERROR'
                );
            }
        } else if (data.type === 'backBtnPressed') {
            console.log(
                'backBtnPressed!!',
                window.history.state.idx,
                'window.history.length',
                window.history.length,
                'history.location.pathname',
                history.location.pathname
            );
            if (
                (getUserAgent() === 'android' && history.location.pathname === '/') ||
                (getUserAgent() === 'android' && history.location.pathname === '/login')
            ) {
                dispatch(setBackpressedCount(myState.etc.backpressedCount + 1));

                if (myState.etc.backpressedCount === 2) {
                    dispatch(setBackpressedCount(0));
                    window.ReactNativeWebView?.postMessage(JSON.stringify({type: 'exitApp'}));
                } else {
                    // 뒤로가기를 누르면 앱이 종료할 것인지 물어봐야 함 3초카운트 시작
                    // 3초내에 아무 동작하지않으면 종료안한것
                    // back버튼을 한번더 눌러서 count == 2값이 되면 앱 종료
                    setTimeout(() => {
                        dispatch(resetBackpressedCount());
                    }, 3000);

                    window.ReactNativeWebView?.postMessage(
                        JSON.stringify({type: 'isRealExitApp', value: myState.etc.backpressedCount + 1})
                    );
                }
            } else {
                window.history.back();
            }
        } else if (data.type === 'checkPhotoCameraPermission') {
            const value = JSON.parse(event?.data).value;
            dispatch(setPhotoCameraPermission(value));

            if (value === 'GRANTED') {
                if (myState.permission.whereCallFrom === 'ModifyProfile') {
                    openImgLoadWindowModifyProfile();
                } else if (myState.permission.whereCallFrom === 'UploadImg') {
                    openImgLoadWindowUploadImg();
                }
            }
        } else if (data.type === 'appVersion') {
            dispatch(setAppVersion(data.result));
        } else if (data.type === 'isRealDeleteMember') {
            if (data.result) {
                const payload = {
                    authorizationCode: localStorageUtil.get('accessToken'),
                    memberId: localStorageUtil.get('memberId')
                };

                const withdrawalUrl =
                    localStorageUtil.get('loginType') === 'kakao' ? 'auth/kakao-unlink' : 'auth/apple-unlink';

                axioswrapper
                    .Axios('POST', withdrawalUrl, payload)
                    .then((response) => {
                        console.log('withdrawalUrl ok.. say good bye...see....you.... ;(', response);
                        localStorageUtil.allClear();
                        stackRouterReset('Login', '/login');
                    })
                    .catch((error) => {
                        console.log(`${withdrawalUrl} error`, error);
                    });
            }
        } else if (data.type === 'actionBlockReport') {
            console.log('data.result.selectedItem', data.result.selectedItem);
            if (data.result.selectedItem === '숨기기') {
                const payload = {
                    feedId: myState.report.beHiddenFeedItem.id,
                    memberId: localStorageUtil.get('memberId')
                };
                axioswrapper
                    .Axios('POST', 'hide/create', payload)
                    .then((response) => {
                        console.log('hide/create ok', response);
                        stackRouterReset('Home', '/');
                    })
                    .catch((error) => {
                        console.log('hide/create error', error);
                    });
            } else if (data.result.selectedItem === '신고하기') {
                dispatch(setReportDrawerOpen(true));
            } else if (data.result.selectedItem === '차단하기') {
                const payload = {
                    banId: myState.feed.userFeedList.memberId,
                    memberId: localStorageUtil.get('memberId')
                };
                axioswrapper
                    .Axios('POST', 'ban/create', payload)
                    .then((response) => {
                        console.log('ban/create ok', response);
                        stackRouterReset('Home', '/');
                    })
                    .catch((error) => {
                        console.log('ban/create error', error);
                    });
            }
        } else if (data.type === 'getFcmToken') {
            appUtils.sendLogMessage('before Axios getFcmToken', 'here ' + data.result.fcmToken, 'NORMAL');
            if (
                localStorageUtil.get('fcmToken') === null ||
                localStorageUtil.get('fcmToken') === 'null' ||
                localStorageUtil.get('fcmToken') === '' ||
                localStorageUtil.get('fcmToken') !== data.result.fcmToken
            ) {
                localStorageUtil.set('fcmToken', data.result.fcmToken);
                axioswrapper
                    .Axios('POST', 'fcm/apply-token', {
                        token: data.result.fcmToken,
                        memberId: localStorageUtil.get('memberId')
                    })
                    .then((response) => {
                        console.log('fcm/apply-token ok', response);
                    })
                    .catch((error) => {
                        console.log('fcm/apply-token error', error);
                    });
            }
            // axioswrapper
            // .Axios('POST', 'fcm/apply-token', {
            //     token: data.result.fcmToken,
            //     memberId: localStorageUtil.get('memberId')
            // })
            // .then((response) => {
            //     localStorageUtil.set('fcmToken', data.result.fcmToken);
            //     console.log('fcm/apply-token ok', response);
            // })
            // .catch((error) => {
            //     console.log('fcm/apply-token error', error);
            // });

            appUtils.sendLogMessage(
                'App.getMessage().(data.type === getFcmToken)',
                `token: ${data.result.fcmToken}`,
                'NORMAL'
            );
        } else if (data.type === 'requestApplyPush') {
            localStorageUtil.set('isAllowPushPermission', data.result.isApply);

            axioswrapper
                .Axios('POST', 'push/init', {
                    allActivated: data.result.isApply,
                    memberId: localStorageUtil.get('memberId')
                })
                .then((response) => {
                    console.log('push/init ok', response);
                })
                .catch((error) => {
                    console.log('push/init error', error);
                });
        } else if (data.type === 'requestApplyPushInSetting') {
            dispatch(setIsPushSetting(data.result.isApply));
        } else if (data.type === 'stackLength') {
            // RN에서 받은 스택 길이를 localStorage에 저장
            localStorageUtil.set('stackLength', data.result);
        }
    };

    // 사용자 정보 RN에 전송
    useEffect(() => {
        if (userId && userName && refreshToken) {
            window.ReactNativeWebView?.postMessage(
                JSON.stringify({
                    type: 'userData',
                    userData: {
                        memberId: userId,
                        name: userName,
                        refreshToken: refreshToken
                    }
                })
            );
        }
    }, [userId, userName, refreshToken]);

    return (
        <>
            <AppRoutes />
        </>
    );
}

export default App;
