import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useSearchParams} from 'react-router-dom';
import {stackRouterBack, stackRouterPush} from '../../_helpers/StackRouter';
import {setIsClickBack} from '../../_store/search.slice';
import { setMakePinOpen } from '../../_store/makePin.slice';
import IconButton from '../Button/IconButton/IconButton';
import Icon from '../Icon/Icon';
import Navigation from '../Navigation/Navigation';
import BlockReport from '../Report/BlockReport/BlockReport';
import styles from './Header/Header.module.scss';

function FeedDeatilLayout(props) {
    const {pageTitle, pinDetailData, children, isMine, back, nav, whareCalled} = props;

    const dispatch = useDispatch();
    const [serchParams] = useSearchParams();
    const pageType = serchParams.get('pageType');
    const {makePinOpen} = useSelector((state) => state.makePin);

    // pin 생성하기 drawer 열려있을때 > drawer 이외의 위치 클릭시 drawer 닫힘
    const handleLayoutClick = (event) => {
        const drawer = document.querySelector('.MuiDrawer-root');

        if(makePinOpen && drawer) {
            dispatch(setMakePinOpen(!makePinOpen));
        }
    };

    // 설정 및 개인정보로 이동
    const goToSetting = () => {
        stackRouterPush('Setting', '/setting');
    };

    // back 버튼 클릭
    const handleBackBtnClick = () => {
        if (pageTitle && pageTitle.includes('게시물')) {
            dispatch(setIsClickBack(true));
        }
        stackRouterBack();
    };

    return (
        <div id="contents" onClick={handleLayoutClick}>
            <header id={styles.header}>
                <div className={styles.headerWrap}>
                    {back && (
                        <button type="button" className={styles.btnBack} onClick={handleBackBtnClick}>
                            <Icon name="back" width="24" height="24" />
                        </button>
                    )}

                    <h1>{pageTitle}</h1>

                    {/* 숨기기/신고하기 */}
                    {isMine === false && (
                        <BlockReport pinDetailData={pinDetailData} whareCalled={whareCalled} />
                    )}

                    {/* 사용자 메인 피드 & 내 계정일때 */}
                    {pageType === 'myPage' && isMine && (
                        <>
                            <IconButton
                                name="menu"
                                width="24"
                                height="24"
                                className={styles.btnLogout}
                                onClick={goToSetting}
                            />
                        </>
                    )}
                </div>
            </header>

            <div className="contentsArea">{children}</div>

            {nav !== false && <Navigation activeNav={isMine ? 'myPage' : ''} />}
        </div>
    );
}

export default FeedDeatilLayout;
