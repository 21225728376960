import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import Kakao from 'kakaojs';

import {stackRouterReset} from '../../_helpers/StackRouter';
import {history} from '../../_helpers/history';
import {setIsLoginLoading, setIsLogined} from '../../_store/auth.slice';
import iconApple from '../../assets/images/icon/icon_apple.png';
import iconKakao from '../../assets/images/icon/icon_kakao.png';
import iconLogo from '../../assets/images/icon/logo.svg';
import LoadingProgress from '../../components/Loading/LoadingProgress';
import {appUtils} from '../../utils/AppUtils';
import axioswrapper from '../../utils/Axios';
import {localStorageUtil} from '../../utils/LocalStorage';
import getUserAgent from '../../utils/UserAgent';
import styles from './Login.module.scss';

function Login() {
    const [loginCode, setLoginCode] = useState('');
    const {isLogined, isLoginLoading} = useSelector((state) => state.auth);
    const dispatch = useDispatch();

    useEffect(() => {
        const loginType = localStorageUtil.get('loginType');

        if (loginType === 'apple' && !isLogined && loginCode === '') {
            const hash = window.location.hash.substring(1);
            const params = {};
            hash.split('&').forEach((part) => {
                const [key, value] = part.split('=');
                params[key] = decodeURIComponent(value);
            });

            setLoginCode(params.code);

            if (params.code !== null) {
                try {
                    appUtils.sendLogMessage('Login.UseEffect([]).appleLogin', '', 'NORMAL');

                    const payload = {
                        code: params.code
                    };

                    dispatch(setIsLoginLoading(true));
                    // wepin서버로 로그인요청
                    axioswrapper
                        .Axios('POST', 'auth/apple-login', payload)
                        .then((response) => {
                            console.log('auth/apple-login ok', response);

                            localStorageUtil.set('loginType', 'apple');
                            localStorageUtil.set('appleToken', response.data.data.memberDto.appleToken);
                            localStorageUtil.set('email', response.data.data.memberDto.email);
                            localStorageUtil.set('memberId', response.data.data.memberDto.id);
                            localStorageUtil.set('loginAuth', response.data.data.memberDto.auth);

                            dispatch(setIsLogined(response.data.data.isLogined));
                            dispatch(setIsLoginLoading(false));

                            if (response.data.data.memberDto.isLogined) {
                                const date = new Date();
                                localStorageUtil.set('accessToken', response.data.data.accessToken);
                                localStorageUtil.set('refreshToken', response.data.data.refreshToken);
                                date.setTime(date.getTime() + response.data.data.expiresIn * 1000);

                                localStorageUtil.set('expireAt', date);
                                stackRouterReset('Home', '/');
                            } else {
                                history.navigate(`/join`);
                            }
                        })
                        .catch((error) => {
                            console.log('auth/apple-login called error', error);
                        })
                        .finally(() => {
                            dispatch(setIsLoginLoading(false));
                        });
                } catch (error) {
                    console.log('auth/apple-login error', error);
                    appUtils.sendLogMessage('Login.UseEffect([]).appleLogin', error.message, 'ERROR');
                }
            }
        } else if (loginType === 'kakao' && !isLogined && loginCode === '') {
            const code = new URL(window.location.href).searchParams.get('code');

            if (code !== null) {
                try {

                    const payload = {
                        authorizationCode: code,
                        userAgent: getUserAgent()
                    };

                    dispatch(setIsLoginLoading(true));
                    //wepin서버로 로그인요청
                    axioswrapper
                        .Axios('POST', `auth/kakao-login`, payload)
                        .then((response) => {
                            console.log('auth/kakao-login ok', response);
                            localStorageUtil.set('loginType', 'kakao');
                            localStorageUtil.set('kakaoToken', response.data.data.memberDto.kakaoToken);
                            localStorageUtil.set('email', response.data.data.memberDto.email);
                            localStorageUtil.set('memberId', response.data.data.memberDto.id);
                            localStorageUtil.set('userName', response.data.data.memberDto.username);
                            localStorageUtil.set('loginAuth', response.data.data.memberDto.auth);

                            dispatch(setIsLogined(response.data.data.isLogined));
                            dispatch(setIsLoginLoading(false));

                            if (response.data.data.memberDto.isLogined) {
                                const date = new Date();
                                localStorageUtil.set('accessToken', response.data.data.accessToken);
                                localStorageUtil.set('refreshToken', response.data.data.refreshToken);
                                date.setTime(date.getTime() + response.data.data.expiresIn * 1000);

                                localStorageUtil.set('expireAt', date);
                                stackRouterReset('Home', '/');
                            } else {
                                history.navigate(`/join`);
                            }
                        })
                        .catch((error) => {
                            console.log('error', error);

                            localStorageUtil.set('loginType', '');
                            localStorageUtil.set('kakaoToken', '');
                            localStorageUtil.set('email', '');
                            localStorageUtil.set('loginAuth', '');

                            dispatch(setIsLogined(false));
                            dispatch(setIsLoginLoading(false));
                        })
                        .finally(() => {
                            dispatch(setIsLoginLoading(false));
                        });
                } catch (error) {
                    console.log('auth/kakao-login error', error);
                }
            }
        }
    }, []);

    const kakaoLoginBtnClick = () => {
        localStorageUtil.set('loginType', 'kakao');

        try {
            console.log('userAgent', getUserAgent());
            if (getUserAgent() === 'android') {
                window.ReactNativeWebView?.postMessage(JSON.stringify({type: 'kakaoLoginAndroid'}));
            } else {
                Kakao.Auth.authorize({
                    redirectUri: `${process.env.REACT_APP_WEB_URL}/login`
                })
                    .then()
                    .catch((error) => {
                        console.log('Kakao.Auth.authorize error', error);
                    });
            }
        } catch (e) {
            console.log('Kakao.Auth.authorize error', e);
        }
    };

    const appleLoginBtnClick = () => {
        localStorageUtil.set('loginType', 'apple');
        window.location.href = `https://appleid.apple.com/auth/authorize?client_id=com.wepin.services&redirect_uri=${process.env.REACT_APP_WEB_URL}/login&response_type=code id_token&state=mywepinTest&response_mode=fragment`;
    };

    return (
        <div id={styles.login}>
            {isLoginLoading && <LoadingProgress />}
            <div className={styles.loginWrap}>
                <>
                    <div className={styles.logoArea}>
                        <h1 className={styles.logo}>
                            <img src={iconLogo} alt="wepin" className={styles.logoImg} /> wepin
                        </h1>
                        <p>장소를 핀-하여 소통해 보아요</p>
                    </div>
                    <div className={styles.btnWrap}>
                        <button type="button" className={styles.kakao} onClick={kakaoLoginBtnClick}>
                            <i className={styles.icon}>
                                <img src={iconKakao} alt="카카오" />
                            </i>
                            카카오로 로그인
                        </button>
                        <button type="button" className={styles.apple} onClick={appleLoginBtnClick}>
                            <i className={styles.icon}>
                                <img src={iconApple} alt="애플" />
                            </i>
                            애플로 로그인
                        </button>
                    </div>
                </>
            </div>
        </div>
    );
}

export default Login;
