import React, {useEffect, useState} from 'react';
import styles from './FeedList.module.scss';
import FeedListItem from './FeedListItem';

function FeedList(props) {
    const {feedList, myIsFollow, isMerge=false, getSelectedItemList} = props;
    const [isSelected, setIsSelected] = useState(false);
    const [selectedList, setSelectedList] = useState([]);

    useEffect(() => {
        if(isMerge) {
            setSelectedList(feedList?.map((item) => {
                return {
                    ...item,
                    isSelected: false
                };
            }));
        }
    }, [feedList]);

    useEffect(() => {
        if(isMerge) {
            const onlySelectedList = selectedList?.filter(item => item.isSelected);
            getSelectedItemList(selectedList, onlySelectedList);
        }
    }, [selectedList]);

    const getSelectedItem = (feedItem, isSelected) => {
        setSelectedList(selectedList?.map((item) => {
            return {
                ...item,
                isSelected: item.feedId === feedItem.feedId ? isSelected : item.isSelected
            };
        }));        
    }

    return (
        <div className={styles.feedListWrap}>
            <ul className={styles.feedList}>
                {feedList?.map((feedItem) => {
                    return (
                        <FeedListItem
                            key={feedItem.feedId}
                            feedItem={feedItem}
                            myIsFollow={myIsFollow || feedItem.isFollow}
                            isMerge={isMerge}
                            getSelectedItem={getSelectedItem}
                        />
                    );
                })}
            </ul>
        </div>
    );
}

export default FeedList;
