import React, {useEffect, useState} from 'react';
import {Drawer} from '@mui/material';
import styles from './AddPinDrawer.module.scss';
import {useSelector, useDispatch} from 'react-redux';
import {setPinList, setUpdatePinList, setMakePinStep, setResetPlaceInfo} from '../../../_store/makePin.slice';
import Icon from '../../Icon/Icon';

function AddPinDrawer(props) {
    const {onClickMakePinItem} = props;
    const {makePinOpen} = useSelector((state) => state.makePin);

    return (
        <Drawer anchor="bottom" open={makePinOpen}>
            <div className={styles.contentsWrap}>
                <div className={styles.typeItemWrap} onClick={()=>onClickMakePinItem('addPin')}>
                    <button type="button" >
                        <Icon name="makepin" width='44' height='44' fill="#65676A" />
                    </button>

                    <div className={styles.typeItemTextArea}>
                        <span className={styles.mainTitle}>핀 만들기</span>
                        <span className={styles.subTitle}>사진과 위치를 등록하여 핀을 만들어요</span>
                    </div>
                </div>

                 <div className={styles.typeItemWrap} onClick={()=>onClickMakePinItem('mergePin')}>
                    <button type="button" >
                        <Icon name="mergePin" width='44' height='44' fill="#65676A" />
                    </button>
                    
                    <div className={styles.typeItemTextArea}>
                        <span className={styles.mainTitle}>핀 모아 만들기</span>
                        <span className={styles.subTitle}>내 게시물과 즐겨찾기에서 핀을 조합하여 만들어요</span>
                    </div>
                </div>              
            </div>
        </Drawer>
    );
}

export default AddPinDrawer;
