import React, {useRef, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import styles from './WideMapList.module.scss';
import WideMapListItem from './WideMapListItem';
import axioswrapper from '../../../utils/Axios';
import {appUtils} from '../../../utils/AppUtils';
import getUserAgent from '../../../utils/UserAgent';
import {localStorageUtil} from '../../../utils/LocalStorage';
import {setMainFeedList, setNextMainFeedList} from '../../../_store/feed.slice';
import Spinner from '../../Icon/Spinner';

function WideMapList() {
    const dispatch = useDispatch();
    const scrollRef = useRef(null);
    const {mainFeedList, nextMainFeedList} = useSelector((state) => state.feed);
    const [isLoading, setIsLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [hasNext, setHasNext] = useState(false);
    const [totalCount, setTotalCount] = useState(0);

    useEffect(() => {
        async function loadInitialData() {
            // 첫 번째 페이지 로드
            const hasNextresult1 = await loadData(0);

            // hasNext 값이 true 일때만 그다음 페이지 로드
            if (hasNextresult1) {
                // 두 번째 페이지 로드
                const hasNextresult2 = await loadData(1);

                // 페이지 값 1 증가
                setPage((prevPage) => prevPage + 1);
            }
        }

        loadInitialData();

        // fcm 토큰 확인
        window.ReactNativeWebView?.postMessage(JSON.stringify({type: 'getFcmToken'}));

        // 앱 푸시 권한 정보 있는지 확인하기
        if(getUserAgent() === 'android') {
            if(localStorageUtil.get('isAllowPushPermission') === null 
            || localStorageUtil.get('isAllowPushPermission') === 'null'
            || localStorageUtil.get('isAllowPushPermission') === '') {
                window.ReactNativeWebView?.postMessage(JSON.stringify({type: 'requestApplyPush'}));
            }
        } else {
            window.ReactNativeWebView?.postMessage(JSON.stringify({type: 'requestApplyPush'}));
        }
    }, []);

    const loadData = async (loadPageNum) => {
        try {
            const myMemberId = localStorageUtil.get('memberId');
            const response = await axioswrapper.Axios(
                'GET',
                `feed/list?memberId=${myMemberId}&size=5&page=${loadPageNum}`
            );

            if (loadPageNum === 0) {
                const { lists, totalCount, detail } = response.data.data;
                console.log(detail)

                dispatch(setMainFeedList(lists));
                setTotalCount(totalCount);

                localStorageUtil.set('mainPinIcon', detail.curMainUrl)
                localStorageUtil.set('subPinIcon', detail.curSubUrl)
            }
            else {
                dispatch(setNextMainFeedList(response.data.data.lists));
            }

            // 다음 페이지의 여부 값 저장
            setHasNext(response.data.data.hasNext);
            return response.data.data.hasNext;
        } catch (error) {
            console.log('feed/list error', error);
        }
    };

    // 스크롤 이벤트 핸들러
    const handleScroll = (event) => {
        const {scrollTop, clientHeight, scrollHeight} = event.currentTarget;
        var scrollSub = scrollHeight - Math.ceil(scrollTop);
        if (Math.abs(scrollSub - clientHeight) <= 1) {
            // 토탈 카운트가 메인 리스트의 값과 같지않을때만 뒤에 이어붙이기
            if (totalCount !== mainFeedList.lists.length) {
                // 리덕스 값 nextMainFeedList를 mainFeedList.lists뒤에 붙이기
                dispatch(setMainFeedList([...mainFeedList.lists, ...nextMainFeedList]));
            }

            if (hasNext) {
                // n번째 페이지 리덕스 저장
                loadData(page + 1);
                // 페이지 값 1 증가
                setPage((prevPage) => prevPage + 1);
            }
        }
    };

    return (
        <div className={styles.wideMapList} ref={scrollRef} onScroll={handleScroll}>
            <ul>
                {mainFeedList?.isLoading && (
                    <li className={styles.listLoading}>
                        <Spinner />
                    </li>
                )}
                {mainFeedList?.lists?.map((feedItem, idx) => (
                    <WideMapListItem key={idx} itemId={idx} feedItem={feedItem} />
                ))}
            </ul>
        </div>
    );
}

export default WideMapList;
