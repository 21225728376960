import React, { useState } from 'react';

import {stackRouterPush} from '../../../_helpers/StackRouter';
import styles from './FeedList.module.scss';

function FeedListItem(props) {
    const {feedItem, myIsFollow, isMerge=false, getSelectedItem} = props;
    const [isSelected, setIsSelected] = useState(false);

    const onClickFeedDetail = () => {
        if(isMerge) {
            setIsSelected(!isSelected);
            getSelectedItem(feedItem, !isSelected);
        } else {
            const feedId = feedItem.feedId;
            const isFollow = myIsFollow;
            stackRouterPush('FeedDetail', `/feed/detail?feedId=${feedId}&isFollow=${isFollow}`);
        }
    };

    return (
        <li key={feedItem.feedId} onClick={() => onClickFeedDetail()} className={isSelected ? styles.selected : ''}>
            <div className={styles.previewMap}>
                <img src={feedItem.imgUrl} alt={feedItem.title} />
            </div>
            <div className={styles.pinInfo}>
                <strong className={styles.subject}>{feedItem.title}</strong>
                <span className={styles.count}>{feedItem.pinCnt}핀</span>
            </div>
        </li>
    );
}

export default FeedListItem;
