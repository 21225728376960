import React, {useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { setMakePinOpen } from '../../_store/makePin.slice';
import Navigation from '../Navigation/Navigation';
import Header from './Header/Header';

function Layout(props) {
    const {main, pageTitle, back, isMine, children, nav, activeNav} = props;

    const dispatch = useDispatch();
    const {makePinOpen} = useSelector((state) => state.makePin);

    // pin 생성하기 drawer 열려있을때 > drawer 이외의 위치 클릭시 drawer 닫힘
    const handleLayoutClick = (event) => {
        const drawer = document.querySelector('.MuiDrawer-root');

        if(makePinOpen && drawer) {
            dispatch(setMakePinOpen(!makePinOpen));
        }
    };

    return (
        <div id="contents" onClick={handleLayoutClick}>
            <Header main={main} pageTitle={pageTitle} back={back} isMine={isMine} />

            <div className="contentsArea">{children}</div>

            {nav !== false && <Navigation activeNav={activeNav} />}
        </div>
    );
}

export default Layout;
