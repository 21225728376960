import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import LoginOutlinedIcon from '@mui/icons-material/LoginOutlined';
import {LoadingButton} from '@mui/lab';
import {Button, FormControl, InputLabel, TextField, TextareaAutosize} from '@mui/material';
import axios from 'axios';

import {stackRouterReset} from '../../_helpers/StackRouter';
import {resetMakePin, setMakePinStep, setPinInfo} from '../../_store/makePin.slice';
import {resetSch} from '../../_store/search.slice';
import axioswrapper from '../../utils/Axios';
import {localStorageUtil} from '../../utils/LocalStorage';
import {minStaticMapLevel} from '../../utils/constant';
import LoadingArea from '../Loading/LoadingArea';
import PreviewMap from '../Map/PreviewMap';
import MakePinHeader from './MakePinHeader';
import { ApiOutlined } from '@mui/icons-material';

function PreviewMyPin(props) {
    const {makePinClose, isMerge=false} = props;

    const dispatch = useDispatch();
    const {pinList, pinInfo} = useSelector((state) => state.makePin);

    const [loading, setLoading] = useState(false);
    const [activeButton, setActiveButton] = useState(false);

    const [myPinTitleValue, setMyPinTitleValue] = useState(pinInfo.title ? pinInfo.title : '');
    const [myPinDetailValue, setMyPinDetailValue] = useState(pinInfo.desc ? pinInfo.desc : '');
    const [myPinHashTagValue, setMyPinHashTagValue] = useState(pinInfo.hashTag ? pinInfo.hashTag : '');

    const memberId = localStorageUtil.get('memberId');

    const makeTagList = (tagStr) => {
        const hashtags = tagStr.match(/#[^\s#]+/g);

        if (hashtags) {
            const tags = hashtags.map((tag) => tag.slice(1));
            return tags;
        } else {
            return [];
        }
    };

    // 핀 제목 작성 핸들러
    const handleNameChange = (e) => {
        const value = e.target.value;
        setMyPinTitleValue(value);
    };

    // 핀 내용 작성 핸들러
    const handleDetailChange = (e) => {
        const value = e.target.value;
        setMyPinDetailValue(value);
    };

    // 해시태그 작성 핸들러
    const handleHashTagChange = (e) => {
        const value = e.target.value;
        setMyPinHashTagValue(value);
    };

    // 수정하기 버튼 클릭 이벤트
    const handleModifyPinList = () => {
        dispatch(setMakePinStep({current: 'makePinIndex', prev: 'makePinPreview'}));

        // 수정하기 클릭시 핀 제목, 내용 리덕스에 저장
        dispatch(setPinInfo({desc: myPinDetailValue, title: myPinTitleValue, hashTag: myPinHashTagValue}));
    };

    // 핀만들기 취소(닫기)
    const handleCloseMakePin = () => {
        makePinClose();

        setTimeout(() => {
            dispatch(resetSch());
            dispatch(resetMakePin());
        }, 300);
    };

    // Naver StaticMap Api
    const getStaticMap = () => {
        if (pinInfo.zoomLevel === null) {
            window.alert('지도를 불러오고 있습니다.\n잠시후 다시 시도해주세요.');
            return;
        }

        const clientId = process.env.REACT_APP_NAVER_CLIENT_ID; // NAVER API-KEY-ID
        const clientSecret = process.env.REACT_APP_NAVER_CLIENT_SECRET; // NAVER API-KEY
        // const mainPinMarker = `${process.env.REACT_APP_AWS_S3_URL}/pin_img/wepin_marker.svg`;
        // const subPinMarker = `${process.env.REACT_APP_AWS_S3_URL}/pin_img/small_pin.png`;
        const mainPinMarker = localStorageUtil.get('mainPinIcon');
        const subPinMarker = localStorageUtil.get('subPinIcon');
        const width = 375;
        const height = 300;
        const zoomScale = pinInfo.zoomLevel > 5 ? pinInfo.zoomLevel - 1 : minStaticMapLevel;

        // Static Map 서비스 URL 생성
        let staticMapURL = `https://naveropenapi.apigw.ntruss.com/map-static/v2/raster-cors?w=${width}&h=${height}&level=${zoomScale}&scale=2&X-NCP-APIGW-API-KEY-ID=${clientId}&X-NCP-APIGW-API-KEY=${clientSecret}`;
        let centerPos = '';
        let markers = '';

        for (var i = 0; i < pinList.length; i++) {
            if (pinList[i].isMain) {
                markers += `&markers=type:e|anchor:center|icon:${mainPinMarker}|pos:${pinList[i].lng}%20${pinList[i].lat}`;
                centerPos = `&center=${pinList[i].lng},${pinList[i].lat}`;
                staticMapURL += centerPos;
            } else {
                markers += `&markers=type:e|anchor:center|icon:${subPinMarker}|pos:${pinList[i].lng}%20${pinList[i].lat}`;
            }
        }

        staticMapURL += markers;

        axios
            .get(staticMapURL, {responseType: 'arraybuffer'})
            .then((response) => {
                const blob = new Blob([response.data], {type: 'image/png'});
                const reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onloadend = () => {
                    const base64data = reader.result;

                    // 스태틱맵 이미지 변환이 정상적으로 완료되면 handleSubmit 함수 호출
                    submitMakePin(base64data);
                };
            })
            .catch((error) => {
                window.alert('지도를 불러오는중 오류가 발생했습니다.\n잠시후 다시 시도해주세요.');
                console.error('Failed to fetch static map:', error);
            });
    };

    // 핀 게시하기 (게시물 등록)
    const submitMakePin = (base64data) => {
        if (!base64data) {
            window.alert('지도를 불러오는중 오류가 발생했습니다.\n잠시후 다시 시도해주세요.');
            return;
        }

        setLoading(true);

        const payload = {
            desc: myPinDetailValue,
            img: base64data,
            memberId: localStorageUtil.get('memberId'),
            pinList: pinList,
            tagList: makeTagList(myPinHashTagValue),
            title: myPinTitleValue,
            zoomLevel: pinInfo.zoomLevel
        };

        let apiURL = '';
        if(isMerge) {
            payload.pinList = pinList?.map(item => ({ id: item.id, isMain: item.isMain })).flat();
            apiURL = 'feed/merge';
        } else {
            apiURL = 'feed/create';
        }

        axioswrapper
            .Axios('POST', apiURL, payload)
            .then((response) => {
                console.log(`${apiURL} ok`, response);
                handleCloseMakePin();
                setLoading(false);

                // 게시물 등록 후 홈으로 이동
                stackRouterReset('Home', '/');
            })
            .catch((error) => {
                console.log('feed/create error', error);
                setLoading(false);
                window.alert('에러가 발생했습니다. 잠시후 다시 시도해주세요.');
            });
    };

    useEffect(() => {
        // 핀-게시하기 버튼 활성화 여부
        const hashTagCount = (myPinHashTagValue.match(/#[^\s#]+/g) || []).length;
        if (myPinTitleValue && myPinDetailValue && hashTagCount <= 10) {
            setActiveButton(true);
        } else {
            setActiveButton(false);
        }
    }, [myPinTitleValue, myPinDetailValue, myPinHashTagValue]);

    const hashTagCount = (myPinHashTagValue.match(/#[^\s#]+/g) || []).length;
    const hashTagLabelColor = hashTagCount > 10 ? '#FF0000' : '#000000';

    return (
        <>
            {/* 헤더 */}
            {!isMerge && (
                <MakePinHeader makePinClose={makePinClose} handleClickBackEvent={handleModifyPinList} headerTitle={'내 핀 만들기'}/>
            )}

            {/* 핀 미리보기 */}
            <div className="previewMyPinArea">
                <div className="scrollArea">
                    <div className="mapArea">
                        <PreviewMap />
                        <Button
                            component="label"
                            variant="contained"
                            startIcon={<DriveFileRenameOutlineIcon />}
                            color="medGray"
                            className="btnMyPinModify"
                            onClick={handleModifyPinList}
                        >
                            수정하기
                        </Button>
                    </div>

                    <div className="section">
                        <div className="formArea">
                            <FormControl>
                                <InputLabel shrink>핀 제목</InputLabel>
                                <TextField
                                    size="small"
                                    value={myPinTitleValue}
                                    onChange={handleNameChange}
                                    placeholder="핀 제목 작성"
                                />
                            </FormControl>
                            <FormControl>
                                <InputLabel>자세한 내용</InputLabel>
                                <TextareaAutosize
                                    minRows={5}
                                    maxRows={5}
                                    value={myPinDetailValue}
                                    onChange={handleDetailChange}
                                    placeholder={
                                        '게시하려는 핀-의 내용을 작성해 주세요\n\n욕설 및 불쾌한 내용을 작성할 경우 신고와 이용이 제한 될 수 있어요'
                                    }
                                />
                            </FormControl>
                            <FormControl>
                                <InputLabel shrink>
                                    해시태그{' '}
                                    <span style={{color: hashTagLabelColor}}>({hashTagCount} / 10)</span>
                                </InputLabel>
                                <TextField
                                    size="small"
                                    value={myPinHashTagValue}
                                    onChange={handleHashTagChange}
                                    placeholder="#을 이용해 태그를 입력해 보세요 (최대 10개)"
                                />
                            </FormControl>
                        </div>
                    </div>
                </div>

                <div className="fixedBottomBtnArea">
                    <LoadingButton
                        variant="contained"
                        fullWidth={true}
                        size="large"
                        loading={loading} // 로딩중 유무
                        loadingPosition="start"
                        startIcon={<LoginOutlinedIcon />}
                        disabled={!activeButton}
                        onClick={getStaticMap}>
                            핀-게시하기
                    </LoadingButton>
                </div>
            </div>

            {loading && <LoadingArea />}
        </>
    );
}

export default PreviewMyPin;
