import axios from 'axios';

import {stackRouterReset} from '../_helpers/StackRouter';
import {localStorageUtil} from '../utils/LocalStorage';
import {tokenValidate} from '../utils/TokenValidate';
import {appUtils} from './AppUtils';

// 사용방법
// request : 'GET', 'POST', 'PUT', 'DELETE'
// url : (ex. auth/kakao-login) REACT_APP_WEB_URL은 생략
// payload :
// ex. {
//    name: 'jinkyeong',
//    code: 'abcde',
//    ...
// }
function Axios(request, url, payload = '') {
    const axiosInstance = axios.create({
        headers: {
            'Content-Type': 'application/json'
        }
    });

    if (localStorageUtil.get('expireAt') && tokenValidate.validate(localStorageUtil.get('expireAt'))) {
        // return axiosInstance
        //     .request({
        //         method: request,
        //         url: `${process.env.REACT_APP_API_URL}/${url}`,
        //         data: JSON.stringify(payload)
        //     })
        //     .catch((error) => {
        //         if (error.response && error.response.status === 401) {
        return reIssueToken(axiosInstance).then((response) => {
            if (response.data?.code === 'I999' || response.data?.code === 'E001') {
                console.log(response.data?.code, ' ', response.data?.message);
                localStorageUtil.allClear();
                stackRouterReset('Login', '/login');
            }

            setLocalStorage(response, url);

            return axiosInstance.request({
                method: request,
                url: `${process.env.REACT_APP_API_URL}/${url}`,
                data: JSON.stringify(payload),
                headers: {
                    Authorization: 'Bearer ' + localStorageUtil.get('accessToken')
                }
            });
        });
        //     } else {
        //         throw error;
        //     }
        // });
    } else {
        return axiosInstance.request({
            method: request,
            url: `${process.env.REACT_APP_API_URL}/${url}`,
            data: JSON.stringify(payload),
            headers: {
                Authorization: 'Bearer ' + localStorageUtil.get('accessToken')
            }
        });
    }
}

// function AxiosRaw(request, url, payload = '') {
//     const axiosInstance = axios.create({
//         headers: {
//             'Content-Type': 'application/json'
//         }
//     });

//     return axiosInstance.request({
//         method: request,
//         url: url,
//         data: payload
//     });
// }

function reIssueToken() {
    const axiosInstance = axios.create({
        headers: {
            'Content-Type': 'application/json'
        }
    });

    return axiosInstance.request({
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL}` + '/auth/token-re-issue',
        data: JSON.stringify({refreshToken: localStorageUtil.get('refreshToken')})
    });
}

function setLocalStorage(response, url) {

    if (response.data.data.expiresIn !== null && response.data.data.expiresIn !== 'null') {
        const date = new Date();
        date.setTime(date.getTime() + response.data.data.expiresIn * 1000);
        localStorageUtil.set('expireAt', date);
    }

    localStorageUtil.set('accessToken', response.data.data.accessToken);
    localStorageUtil.set('refreshToken', response.data.data.refreshToken);
}

export default {Axios};
