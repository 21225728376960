import React, {useState, useRef, useEffect} from 'react';
import { TabContext, TabList, TabPanel} from '@mui/lab';
import {Tab} from '@mui/material';
import axioswrapper from '../../utils/Axios';
import { localStorageUtil } from '../../utils/LocalStorage';
import FeedList from '../List/FeedList/FeedList';

function MergeMyPin(props) {
    const {getSelectedList} = props;
    const [value, setValue] = useState('post');
    const myFeedRef = useRef();
    const [myFeedList, setMyFeedList] = useState();
    const myId = localStorageUtil.get('memberId');
    const [favoritFeedList, setFavoritFeedList] = useState();

    useEffect(() => {
        getPostList();
        // getFavoritesList();
    }, []);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const getPostList = () => {
        axioswrapper
        .Axios('GET', `feed/mine?memberId=${myId}`)
        .then((response) => {
            setMyFeedList(response.data.data.lists);
        })
        .catch((error) => {
            console.log('MergePin member/user-main 실패', error);
        });
    }

    const getSelectedItemList = (selectedList, onlySelectedList) => {
        getSelectedList(selectedList, onlySelectedList);
    };

    return (
        <>
            <TabContext value={value}>
                <div>
                    <TabList
                        variant="fullWidth"
                        textColor="secondary"
                        indicatorColor="secondary"
                        onChange={handleChange}>
                        <Tab label="게시물" value="post" />
                        <Tab label="즐겨찾기" value="favorites" />
                    </TabList>
                </div>

                <TabPanel value="post" ref={myFeedRef}>
                    <div className="totalCountArea">
                        내 게시물 <strong>3475</strong>
                    </div>
                    <FeedList 
                        feedList={myFeedList} 
                        isMerge={true} 
                        getSelectedItemList={getSelectedItemList}/>
                </TabPanel>

                <TabPanel value="favorites" >
                    <div className="totalCountArea">
                        즐겨찾기 <strong>3475</strong>
                    </div>
                    <FeedList feedList={favoritFeedList?.feedList} isMerge={true} getSelectedItemList={getSelectedItemList}/>
                </TabPanel>
            </TabContext>
        </>
    )
}

export default MergeMyPin
