import React from 'react';

const getPath = (name, props) => {
    switch (name) {
        case 'rectWrite':
            return (
                <>
                    <rect
                        x="4.66699"
                        y="4.6665"
                        width="18.6667"
                        height="18.6667"
                        stroke={props.fill}
                        strokeWidth="2"
                    />
                    <line x1="13.833" y1="8.75" x2="13.833" y2="19.25" stroke={props.fill} strokeWidth="2" />
                    <line
                        x1="8.75"
                        y1="14.1665"
                        x2="19.25"
                        y2="14.1665"
                        stroke={props.fill}
                        strokeWidth="2"
                    />
                </>
            );
        case 'rectWriteS':
            return (
                <>
                    <rect x="4.5" y="4" width="16" height="16" stroke={props.fill} strokeWidth="2" />
                    <line x1="12.5" y1="7.5" x2="12.5" y2="16.5" stroke={props.fill} strokeWidth="2" />
                    <line x1="8" y1="12" x2="17" y2="12" stroke={props.fill} strokeWidth="2" />
                </>
            );
        case 'home':
            return (
                <>
                    <path
                        {...props}
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M12.5 3L3.5 8.4V21H21.5V8.4L12.5 3ZM11.75 14V18H13.25V14H11.75Z"
                    />
                </>
            );
        case 'homeLine':
            return (
                <>
                    <path
                        d="M12.5 14V18M3.5 8.4V21H21.5V8.4L12.5 3L3.5 8.4Z"
                        stroke={props.fill}
                        strokeWidth="1.5"
                    />
                </>
            );
        case 'search':
            return (
                <>
                    <path
                        d="M18 18L13.8033 13.8033M16 8.5C16 4.35786 12.6421 1 8.5 1C4.35786 1 1 4.35786 1 8.5C1 12.6421 4.35786 16 8.5 16C12.6421 16 16 12.6421 16 8.5Z"
                        stroke={props.fill}
                        strokeWidth="2"
                        strokeLinecap="square"
                        strokeLinejoin="round"
                    />
                </>
            );
        case 'search_thin':
            return (
                <>
                    <circle cx="9" cy="9" r="5.2" stroke={props.fill} strokeWidth="1.6" />
                    <path
                        d="M13.0001 13L16.5355 16.5355"
                        stroke={props.fill}
                        strokeWidth="1.6"
                        strokeLinecap="round"
                    />
                </>
            );
        case 'myPage':
            return (
                <>
                    <path
                        {...props}
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M12.5 11C14.7091 11 16.5 9.20914 16.5 7C16.5 4.79086 14.7091 3 12.5 3C10.2909 3 8.5 4.79086 8.5 7C8.5 9.20914 10.2909 11 12.5 11ZM7.5 13C5.29086 13 3.5 14.7909 3.5 17V21H21.5V17C21.5 14.7909 19.7091 13 17.5 13H7.5Z"
                    />
                </>
            );
        case 'myPageLine':
            return (
                <>
                    <path
                        {...props}
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M10 6.5C10 5.11929 11.1193 4 12.5 4C13.8807 4 15 5.11929 15 6.5C15 7.88071 13.8807 9 12.5 9C11.1193 9 10 7.88071 10 6.5ZM12.5 2C10.0147 2 8 4.01472 8 6.5C8 8.98528 10.0147 11 12.5 11C14.9853 11 17 8.98528 17 6.5C17 4.01472 14.9853 2 12.5 2ZM7.5 13C4.73858 13 2.5 15.2386 2.5 18V21V22H3.5H21.5H22.5V21V18C22.5 15.2386 20.2614 13 17.5 13H7.5ZM4.5 18C4.5 16.3431 5.84315 15 7.5 15H17.5C19.1569 15 20.5 16.3431 20.5 18V20H4.5V18Z"
                    />
                </>
            );
        case 'like':
        case 'like-on':
            return (
                <>
                    <path
                        {...(name === 'like-on' && {...props})}
                        d="M3.6095 10.0421L10 16.6693L16.3905 10.0421C17.1009 9.30537 17.5 8.30618 17.5 7.26431C17.5 5.09473 15.804 3.33594 13.7119 3.33594C12.7073 3.33594 11.7438 3.74982 11.0334 4.48653L10 5.55816L8.96664 4.48653C8.25624 3.74982 7.29273 3.33594 6.28807 3.33594C4.19598 3.33594 2.5 5.09473 2.5 7.26431C2.5 8.30618 2.8991 9.30537 3.6095 10.0421Z"
                        stroke={props.fill}
                        strokeWidth="1.6"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </>
            );
        case 'like-thin':
        case 'like-thin-on':
            return (
                <>
                    <path
                        {...(name === 'like-thin-on' && {...props})}
                        d="M4.3314 12.0474L12 20L19.6686 12.0474C20.5211 11.1633 21 9.96429 21 8.71405C21 6.11055 18.9648 4 16.4543 4C15.2487 4 14.0925 4.49666 13.24 5.38071L12 6.66667L10.76 5.38071C9.90749 4.49666 8.75128 4 7.54569 4C5.03517 4 3 6.11055 3 8.71405C3 9.96429 3.47892 11.1633 4.3314 12.0474Z"
                        stroke={props.fill}
                        strokeWidth="1.4"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </>
            );
        case 'comment':
            return (
                <>
                    <path
                        d="M4 5H20V18H7.5L4 21V5Z"
                        stroke={props.fill}
                        strokeWidth="1.4"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M7.5 8.5H16.5M7.5 11.5H16.5M7.5 14.5H13.2"
                        stroke={props.fill}
                        strokeWidth="1.2"
                        strokeLinecap="round"
                    />
                </>
            );
        case 'share':
            return (
                <>
                    <path
                        d="M4 8.39286V20H20V16.75M7.55556 16.2857C7.55556 9.78571 12 7 19.1111 7"
                        stroke={props.fill}
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M17 4.17188L19.8284 7.0003L17 9.82873"
                        stroke={props.fill}
                        strokeWidth="1.5"
                        strokeLinecap="round"
                    />
                </>
            );
        case 'back':
            return (
                <>
                    <path
                        {...props}
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M17.1314 4.00002L16.5657 4.56571L9.13139 12L16.5657 19.4343L17.1314 20L16 21.1314L15.4343 20.5657L7.43434 12.5657L6.86865 12L7.43434 11.4343L15.4343 3.43434L16 2.86865L17.1314 4.00002Z"
                    />
                </>
            );
        case 'close':
            return (
                <>
                    <line
                        x1="18.5761"
                        y1="18.3856"
                        x2="5.61245"
                        y2="5.42197"
                        stroke={props.fill}
                        strokeWidth="1.4"
                    />
                    <line
                        x1="18.3876"
                        y1="5.42466"
                        x2="5.42393"
                        y2="18.3883"
                        stroke={props.fill}
                        strokeWidth="1.4"
                    />
                </>
            );
        case 'close_b':
            return (
                <>
                    <line
                        x1="19.9197"
                        y1="19.637"
                        x2="4.36332"
                        y2="4.08062"
                        stroke="#111111"
                        strokeWidth="1.6"
                    />
                    <line
                        x1="19.637"
                        y1="4.08033"
                        x2="4.08062"
                        y2="19.6367"
                        stroke="#111111"
                        strokeWidth="1.6"
                    />
                </>
            );
        case 'way':
            return (
                <path
                    {...props}
                    d="M6.25 5.69167L4.925 7.00833L3.75 5.83333L7.08333 2.5L10.4167 5.83333L9.24167 7.00833L7.91667 5.69167V10.8333H14.5833C15.5 10.8333 16.25 11.5833 16.25 12.5V17.5H14.5833V12.5H7.91667C7 12.5 6.25 11.75 6.25 10.8333V5.69167Z"
                />
            );
        case 'delete':
            return (
                <path
                    {...props}
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.5 17C13.1944 17 17 13.1943 17 8.5C17 3.80566 13.1944 0 8.5 0C3.80557 0 0 3.80566 0 8.5C0 13.1943 3.80557 17 8.5 17ZM12.2657 4.73438C12.5781 5.04663 12.5781 5.55322 12.2657 5.86572L9.63138 8.5L12.2657 11.1343C12.5781 11.4468 12.5781 11.9534 12.2657 12.2656C11.9533 12.5781 11.4467 12.5781 11.1343 12.2656L8.5 9.63135L5.86569 12.2656C5.55328 12.5781 5.04675 12.5781 4.73431 12.2656C4.42191 11.9534 4.42191 11.4468 4.73431 11.1343L7.36862 8.5L4.73431 5.86572C4.42191 5.55322 4.42191 5.04663 4.73431 4.73438C5.04672 4.42188 5.55325 4.42188 5.86569 4.73438L8.5 7.36865L11.1343 4.73438C11.4467 4.42188 11.9533 4.42188 12.2657 4.73438Z"
                />
            );
        case 'delete_circle':
            return (
                <>
                    <rect {...props} width="16" height="16" rx="8" />
                    <line x1="11.8886" y1="11.8848" x2="4.11046" y2="4.10663" stroke="white" />
                    <line x1="11.8887" y1="4.11137" x2="4.11053" y2="11.8895" stroke="white" />
                </>
            );
        case 'gps':
            return (
                <path
                    {...props}
                    d="M8.37778 7.22222H2.88489C2.45286 7.22222 2.32764 6.63216 2.72243 6.4567L14.0496 1.42238C14.3844 1.27362 14.7264 1.61564 14.5776 1.95036L9.5433 13.2776C9.36784 13.6724 8.77778 13.5471 8.77778 13.1151V7.62222C8.77778 7.40131 8.59869 7.22222 8.37778 7.22222Z"
                />
            );
        case 'current_gps':
            return (
                <>
                    <g filter="url(#filter0_d_158_12493)">
                        <rect x="8" y="4" width="40" height="40" rx="20" fill="white" />
                    </g>
                    <path
                        {...props}
                        d="M26.2 25H20.7698C19.9057 25 19.6553 23.8199 20.4449 23.4689L33.0993 17.8448C33.7687 17.5472 34.4528 18.2313 34.1552 18.9007L28.531 31.5551C28.1801 32.3447 27 32.0943 27 31.2302V25.8C27 25.3582 26.6418 25 26.2 25Z"
                    />
                    <defs>
                        <filter
                            id="filter0_d_158_12493"
                            x="0"
                            y="0"
                            width="56"
                            height="56"
                            filterUnits="userSpaceOnUse"
                            colorInterpolationFilters="sRGB"
                        >
                            <feFlood floodOpacity="0" result="BackgroundImageFix" />
                            <feColorMatrix
                                in="SourceAlpha"
                                type="matrix"
                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                result="hardAlpha"
                            />
                            <feOffset dy="4" />
                            <feGaussianBlur stdDeviation="4" />
                            <feComposite in2="hardAlpha" operator="out" />
                            <feColorMatrix
                                type="matrix"
                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.14 0"
                            />
                            <feBlend
                                mode="normal"
                                in2="BackgroundImageFix"
                                result="effect1_dropShadow_158_12493"
                            />
                            <feBlend
                                mode="normal"
                                in="SourceGraphic"
                                in2="effect1_dropShadow_158_12493"
                                result="shape"
                            />
                        </filter>
                    </defs>
                </>
            );
        case 'arr_right':
            return (
                <path
                    {...props}
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M3.81792 12.1671L4.24218 11.7428L8.48458 7.50043L4.24218 3.25803L3.81792 2.83376L4.66644 1.98524L5.09071 2.4095L9.75738 7.07617L10.1816 7.50043L9.75738 7.92469L5.09071 12.5914L4.66645 13.0156L3.81792 12.1671Z"
                />
            );
        case 'modify':
            return (
                <>
                    <path {...props} d="M12.5 13.333L9.16667 16.6663H17.5V13.333H12.5Z" />
                    <path
                        {...props}
                        d="M10.05 5.99134L2.5 13.5413V16.6663H5.625L13.175 9.11634L10.05 5.99134ZM4.93333 14.9997H4.16667V14.233L10.05 8.34967L10.8167 9.11634L4.93333 14.9997Z"
                    />
                    <path
                        {...props}
                        d="M15.5917 6.69967C15.9167 6.37467 15.9167 5.84967 15.5917 5.52467L13.6417 3.57467C13.475 3.40801 13.2667 3.33301 13.05 3.33301C12.8417 3.33301 12.625 3.41634 12.4667 3.57467L10.9417 5.09967L14.0667 8.22467L15.5917 6.69967Z"
                    />
                </>
            );
        case 'add':
            return (
                <>
                    <line x1="19.5" y1="11.8" x2="4.5" y2="11.8" stroke={props.fill} strokeWidth="1.6" />
                    <line x1="11.8" y1="4.5" x2="11.8" y2="19.5" stroke={props.fill} strokeWidth="1.6" />
                </>
            );
        case 'wepin_marker':
            return (
                <>
                    <path
                        d="M18.7883 42.7004L20.4963 44.4084L22.2043 42.7004L32.4525 32.4522C39.0557 25.849 39.0557 15.1431 32.4525 8.53996C25.8493 1.93676 15.1433 1.93676 8.54012 8.53996C1.93691 15.1431 1.93692 25.849 8.54013 32.4522L18.7883 42.7004Z"
                        fill="white"
                        stroke={props.fill}
                        strokeWidth="4.83102"
                    />
                    <path
                        d="M18.7867 35.88L20.4948 37.588L22.2028 35.88L29.0349 29.0479C33.7515 24.3313 33.7515 16.6843 29.0349 11.9677C24.3183 7.25115 16.6712 7.25115 11.9546 11.9677C7.23806 16.6843 7.23806 24.3313 11.9546 29.0479L18.7867 35.88Z"
                        fill={props.fill}
                        stroke="white"
                        strokeWidth="4.83102"
                    />
                    <rect
                        width="4.83102"
                        height="4.83102"
                        transform="matrix(0.707108 0.707106 -0.707108 0.707106 23.9087 30.7578)"
                        fill={props.fill}
                    />
                    <ellipse cx="20.5" cy="51" rx="3.5" ry="2" fill="url(#paint0_radial_158_12223)" />
                    <defs>
                        <radialGradient
                            id="paint0_radial_158_12223"
                            cx="0"
                            cy="0"
                            r="1"
                            gradientUnits="userSpaceOnUse"
                            gradientTransform="translate(20.5 51) rotate(90) scale(2 3.5)"
                        >
                            <stop stopColor={props.fill} stopOpacity="0.4" />
                            <stop offset="1" stopColor={props.fill} stopOpacity="0" />
                        </radialGradient>
                    </defs>
                </>
            );
        case 'menu':
            return (
                <>
                    <g clipPath="url(#clip0_898_3690)">
                        <rect {...props} x="3" y="5.60059" width="18" height="1.6" rx="0.8" />
                        <rect {...props} x="3" y="11.2002" width="18" height="1.6" rx="0.8" />
                        <path
                            {...props}
                            d="M9.5 17.5998C9.5 17.158 9.85817 16.7998 10.3 16.7998H20.2002C20.6421 16.7998 21.0002 17.158 21.0002 17.5998C21.0002 18.0416 20.6421 18.3998 20.2002 18.3998H10.3C9.85817 18.3998 9.5 18.0416 9.5 17.5998Z"
                        />
                    </g>
                    <defs>
                        <clipPath id="clip0_898_3690">
                            <rect width="18" height="18" fill="white" transform="translate(3 3)" />
                        </clipPath>
                    </defs>
                </>
            );
        case 'dotMenu':
            return (
                <>
                    <circle cx="4.9999" cy="12.0004" r="1.6" {...props} />
                    <circle cx="11.9999" cy="12.0004" r="1.6" {...props} />
                    <circle cx="18.9999" cy="12.0004" r="1.6" {...props} />
                </>
            );
        case 'alarm':
            return (
                <>
                    <path
                        d="M14 21H10M18 8C18 6.4087 17.3679 4.88258 16.2426 3.75736C15.1174 2.63214 13.5913 2 12 2C10.4087 2 8.88258 2.63214 7.75736 3.75736C6.63214 4.88258 6 6.4087 6 8C6 11.0902 5.22047 13.206 4.34967 14.6054C3.61513 15.7859 3.24786 16.3761 3.26133 16.5408C3.27624 16.7231 3.31486 16.7926 3.46178 16.9016C3.59446 17 4.19259 17 5.38886 17H18.6111C19.8074 17 20.4055 17 20.5382 16.9016C20.6851 16.7926 20.7238 16.7231 20.7387 16.5408C20.7521 16.3761 20.3849 15.7859 19.6503 14.6054C18.7795 13.206 18 11.0902 18 8Z"
                        stroke={props.fill}
                        strokeWidth="2"
                        strokeLinecap="square"
                        strokeLinejoin="round"
                    />
                </>
            );
        case 'customPin':
            return (
                <>
                    <g clipPath="url(#clip0_2025_6853)">
                        <path
                            d="M9.72209 15.6398C6.85824 12.7759 6.85823 8.13274 9.72207 5.2689C12.5859 2.40505 17.2291 2.40504 20.093 5.26888C22.9568 8.13272 22.9568 12.7759 20.093 15.6398L14.9076 20.8252L9.72209 15.6398Z"
                            fill="white"
                            stroke={props.fill}
                            strokeWidth="2"
                        />
                        <path
                            d="M4.85197 15.6398C1.98812 12.7759 1.98811 8.13274 4.85195 5.2689C7.7158 2.40505 12.359 2.40504 15.2229 5.26888C18.0867 8.13272 18.0867 12.7759 15.2229 15.6398L10.0374 20.8252L4.85197 15.6398Z"
                            fill="white"
                            stroke={props.fill}
                            strokeWidth="2"
                        />
                        <path
                            d="M12.7866 10.166C12.7866 11.6848 11.5554 12.916 10.0366 12.916C8.51784 12.916 7.28662 11.6848 7.28662 10.166C7.28662 8.64723 8.51784 7.41602 10.0366 7.41602C11.5554 7.41602 12.7866 8.64723 12.7866 10.166Z"
                            stroke={props.fill}
                            strokeWidth="2"
                        />
                    </g>
                    <defs>
                        <clipPath id="clip0_2025_6853">
                            <rect width="24" height="24" fill="white" transform="translate(0.5)" />
                        </clipPath>
                    </defs>
                </>
            );
        case 'makepin':
            return (
                <>
                    <rect y="0.5" width="44" height="44" rx="22" fill="#F4F7FE"/>
                    <rect x="12" y="12" width="20" height="20" fill="#297FFF"/>
                    <rect x="14" y="25" width="16" height="5" fill="#F4F7FE"/>
                </>
            );
        case 'mergePin':
            return (
                <>
                    <rect y="0.5" width="44" height="44" rx="22" fill="#F4F7FE"/>
                    <g clipPath="url(#clip0_2158_8434)">
                        <rect x="13" y="13" width="7" height="7" fill="#297FFF" stroke="#297FFF" strokeWidth="2"/>
                        <rect x="13" y="24" width="7" height="7" stroke="#297FFF" strokeWidth="2"/>
                        <rect x="24" y="13" width="7" height="7" fill="#297FFF" stroke="#297FFF" strokeWidth="2"/>
                        <rect x="24" y="24" width="7" height="7" fill="#297FFF" stroke="#297FFF" strokeWidth="2"/>
                    </g>
                    <defs>
                        <clip-path id="clip0_2158_8434">
                            <rect width="20" height="20" fill="white" transform="translate(12 12)"/>
                        </clip-path>
                    </defs>
                </>     
            );
        default:
            return <path />;
    }
};

const Icon = ({
    name = '',
    style = {},
    fill = '#212121',
    viewBox = '',
    width = '100%',
    className = '',
    height = '100%'
}) => (
    <svg
        width={width}
        style={style}
        height={height}
        className={className}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        viewBox={viewBox || `0 0 ${width} ${height}`}
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        {getPath(name, {fill})}
    </svg>
);

export default Icon;
