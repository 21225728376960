import React from 'react';
import styles from './FeedBottomButton.module.scss';
import Icon from '../../Icon/Icon';
import {history} from '../../../_helpers/history'

function FeedBottomButton(props) {
    const {onClickLike, likeOn, onClickWriteComment, feedId} = props;

    const onClickShareLink = () => {
        window.ReactNativeWebView?.postMessage(
            JSON.stringify({
                type: 'share',
                feedId: feedId
            })
        );
    };

    return (
        <div className={styles.listBtnArea}>
            <button type="button" onClick={onClickLike}>
                <Icon
                    name={likeOn ? 'like-thin-on' : 'like-thin'}
                    width="24"
                    height="24"
                    fill={likeOn ? '#FF4F4F' : '#65676A'}
                />{' '}
                좋아요
            </button>
            <button type="button" onClick={() => onClickWriteComment('writeCommentOpen')}>
                <Icon name="comment" width="24" height="24" fill="#65676A" /> 댓글 달기
            </button>
            <button type="button" onClick={onClickShareLink}>
                <Icon name="share" width="24" height="24" fill="#65676A" /> 공유하기
            </button>
        </div>
    );
}

export default FeedBottomButton;
