import React, {useEffect, useState, useRef, forwardRef} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {Drawer, Tab} from '@mui/material';
import './MergePin.scss';
import MakePinHeader from '../../components/MakePin/MakePinHeader';
import MergePinDrawer from '../../components/Drawer/MergePinDrawer/MergePinDrawer';
import MergeMyPin from '../../components/MergePin/MergeMyPin';
import DeleteDuplicateAddresses from '../../components/MergePin/DeleteDuplicateAddresses';
import { setPinList, setUpdatePinList } from '../../_store/makePin.slice';
import axioswrapper from '../../utils/Axios';
import { localStorageUtil } from '../../utils/LocalStorage';
import PreviewMyPin from '../../components/MakePin/PreviewMyPin';
import { Preview } from '@mui/icons-material';

function MergePin(props) {
    const {mergePinOpen, mergePinClose} = props;
    const mergePinPage = useRef();
    const [mergePinStep, setMergePinStep] = useState('merge'); // merge, delete, post
    const [selectedList, setSelectedList] = useState([]);
    const [onlySelectedList, setOnlySelectedList] = useState([]);
    const [mergePinList, setMergePinList] = useState([]);
    const [isMergeFinish, setIsMergeFinish] = useState(false);
    
    const dispatch = useDispatch();

    useEffect(() => {
        if(isMergeFinish) {
            console.log(mergePinList);
            dispatch(setUpdatePinList(mergePinList));
        }
    }, [mergePinList]);

    const goToNextStep = (state) => {
        setMergePinStep(state);
        if(state === 'delete') {
            getFeedDetailPins();       
        }
    };

    const getFeedDetailPins = async () => {
        let tempMergePinList = [];

        tempMergePinList = onlySelectedList.map((item) => {
            return item.pinList;
        }).flat();

        setMergePinList(tempMergePinList.map((item, index) => {
            return {
                ...item,
                isMain: index === 0 // 0번째 원소만 isMain: true, 나머지는 isMain: false
            };
        }));
        setIsMergeFinish(true);
    };

    const getSelectedList = (selectedList, onlySelectedList) => {
        setSelectedList(selectedList);
        setOnlySelectedList(onlySelectedList);
    };

    return (
        <>
            <Drawer anchor="right" open={mergePinOpen} className="mergePinContents" ref={mergePinPage}>
                <div className="contentsWrap">
                    <MakePinHeader makePinClose={mergePinClose} headerTitle={'핀 모아 만들기'}/>

                    {mergePinStep === 'merge' ? (<MergeMyPin getSelectedList={getSelectedList}/>
                    ) : (
                        mergePinStep === 'delete' ? (<DeleteDuplicateAddresses/>
                    ) : (
                        <PreviewMyPin makePinClose={mergePinClose} isMerge={true}/>
                    ))}
                </div>

                {mergePinStep !== 'post' && (
                    <MergePinDrawer 
                        id='bottomDrawer'
                        margePinDrawerOpen={mergePinOpen} 
                        selectedItemCount={onlySelectedList?.length === undefined ? 0 : onlySelectedList?.length} 
                        goToNextStep={goToNextStep}
                        mergePinStep={mergePinStep} />
                )}
            </Drawer>
        </>
    );
}

export default MergePin;
