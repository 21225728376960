import React from 'react';
import PlaceList from '../List/PlaceList/PlaceList';

function DeleteDuplicateAddresses() {
  return (
    <>
        <div className='mergePinNoti'>
            <h3>중복된 주소지를 삭제해 주세요</h3>
            <span>중복 주소지 등록 시 핀 하나로 표시</span>
        </div>
        <hr/>
        <div className='mergePinListWrapper'>
            <PlaceList isMerge={true}/>
        </div>
    </>
  )
}

export default DeleteDuplicateAddresses
